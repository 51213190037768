body {
  font-family: "Noto Sans 400", sans-serif;
  min-height: 100vh;
  position: relative;
  font-size: 0.9375rem;
  &.open {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
}

// Typography
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Noto Sans 700", sans-serif;
}

strong { font-family: "Noto Sans 700", sans-serif; }

.small-heading {
  display: inline-block;
  font-size: 0.75rem;
  margin-bottom: 1rem;
}

.medium-heading {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 1rem;
}


.subHead {
    font-family: "Noto Sans 100", sans-serif;
    font-style: italic;
    font-weight: normal;
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
// Modals
.modal-content {
  color: $body-color;
  .employee-contact-link {
    svg path {
      fill: $secondary !important;
    }
  }
}

// Links
a {
  outline-offset: 5px;
  outline: 2px solid transparent;
  transition: outline-offset .2s linear;
  &:focus {
    outline: 2px solid $secondary;
    outline-offset: 2px;
  }
}
.site-header a {
  &:focus {
    outline: 2px solid $white;
  }
}

// Skip Link
.skip-link {
  position: absolute;
  background-color: $primary;
  color: #fff;
  display: block;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  left:-10000px;
  top: 0;
  z-index: 10001;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    left: 0;
    text-decoration: none;
  }
}

// Global Alert
#globalAlert {
  @include media-breakpoint-down(md) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 11;
  }
}

// Images
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
}
//[data-responsive-background-image] {
//  background-size: cover;
//  background-position: center center;
//  background-repeat: no-repeat;
//}
//img.responsive-bg {
//  visibility: hidden;
//  position: absolute;
//  top: 0;
//  left: 0;
//}

// Responsive Video
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Helper Classes
.bg-red { background-color: $primary; }
.bg-charcoalBlack { background-color: $secondary; }
.bg-slateGray { background-color: $slate-gray; }
.bg-darkBlue { background-color: $dark-blue; }
.bg-red, .bg-charcoalBlack, .bg-darkBlue { color: $white; }

.bg-gradient-red {
  background: linear-gradient(-30deg, rgba($primary,0.35) 0%, rgba($primary,0.65) 45%, rgba($primary,1) 100%);
}
.bg-gradient-charcoalBlack {
  background: linear-gradient(-30deg, rgba($secondary,0.35) 0%, rgba($secondary,0.65) 45%, rgba($secondary,1) 100%);
}
.bg-gradient-darkBlue {
  background: linear-gradient(-30deg, rgba($dark-blue,0.35) 0%, rgba($dark-blue,0.65) 45%, rgba($dark-blue,1) 100%);
}

.mt-slight { margin-top: 1.5rem; @include media-breakpoint-down(xmd) {margin-top: 1rem;} }
.mt-medium { margin-top: 3rem; @include media-breakpoint-down(xmd) {margin-top: 2rem;} }
.mt-max { margin-top: 5rem; @include media-breakpoint-down(xmd) {margin-top: 3rem;} }
.mb-slight { margin-bottom: 1.5rem; @include media-breakpoint-down(xmd) {margin-bottom: 1rem;} }
.mb-medium { margin-bottom: 3rem; @include media-breakpoint-down(xmd) {margin-bottom: 2rem;} }
.mb-max { margin-bottom: 5rem; @include media-breakpoint-down(xmd) {margin-bottom: 3rem;} }

.pt-slight { padding-top: 1.5rem; @include media-breakpoint-down(xmd) {padding-top: 1rem;} }
.pt-medium { padding-top: 3rem; @include media-breakpoint-down(xmd) {padding-top: 2rem;} }
.pt-max { padding-top: 5rem; @include media-breakpoint-down(xmd) {padding-top: 3rem;} }
.pb-slight { padding-bottom: 1.5rem; @include media-breakpoint-down(xmd) {padding-bottom: 1rem;} }
.pb-medium { padding-bottom: 3rem; @include media-breakpoint-down(xmd) {padding-bottom: 2rem;} }
.pb-max { padding-bottom: 5rem; @include media-breakpoint-down(xmd) {padding-bottom: 3rem;} }

.btn-red-outline-reverse {
  @extend .btn;
  @include redOutlineButtonReverse;
  color: $white;
  border-color: $primary;
  border-radius: 10rem;
}
.btn-red-outline {
  @extend .btn;
  @include redOutlineButton;
  color: $secondary;
  border-color: $primary;
  border-radius: 10rem;
}
.btn-dark-gray-outline {
  @extend .btn;
  @include darkGrayButton;
  color: $secondary;
  border-color: $secondary;
  border-radius: 10rem;
}
.btn-dark-gray {
  @extend .btn;
  @include darkGrayButton;
  color: $secondary;
  border-color: transparent;
  border-radius: 10rem;
}
.btn-white-outline {
  @extend .btn;
  @include whiteButton;
  color: $white;
  border-color: $white;
  border-radius: 10rem;
}
.btn-white {
  @extend .btn;
  @include whiteButton;
  color: $white;
  border-color: transparent;
  border-radius: 10rem;
}

.nav-offset {
  padding-top: 94px;
  @include media-breakpoint-up(md) {
    padding-top: 152px;
  }
}
.mt-n-offset {
  margin-top: -7%;
  @include media-breakpoint-up(md) {
    margin-top: -10%;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -7%;
  }
}
.anchor-tag {
  position: relative;
  top: -3rem;
  @include media-breakpoint-up(xmd) {
    top: -5rem;
  }
}

a.news-insights {
  color: black;
}