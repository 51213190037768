@mixin buttonAfter {
  content: '';
  position: relative;
  top:3px;
  display: inline-block;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  width: 35px;
  height: 16.6px;
  margin-left: 0.75rem;
  left: 0;
  transition: left 0.15s ease-in-out;
}
@mixin redOutlineButton {
  &::after {
    @include buttonAfter;
    background-image:url($ra-gray);
  }
  &:hover {
    color: $secondary;
    &::after {
      left: 0.325rem;
    }
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary, 1);
  }
}
@mixin redOutlineButtonReverse {
  &::after {
    @include buttonAfter;
    background-image:url($ra-white);
  }
  &:hover {
    color: $white;
    &::after {
      left: 0.325rem;
    }
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary, 1);
  }
}
@mixin darkGrayButton {
  &::after {
    @include buttonAfter;
    background-image:url($ra-gray);
  }
  &:hover {
    color: $secondary;
    &::after {
      left: 0.325rem;
    }
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($secondary, 1);
  }
}
@mixin whiteButton {
  &::after {
    @include buttonAfter;
    background-image:url($ra-white);
  }
  &:hover {
    color: $white;
    &::after {
      left: 0.325rem;
    }
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($white, 1);
  }
}

@mixin heroCardWhiteBtn {
  &::after {
    @include buttonAfter;
    background-image:url($ra-white);
  }
}

@mixin heroCardGrayBtn {
  &::after {
    @include buttonAfter;
    background-image:url($ra-gray);
  }
}
