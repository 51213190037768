$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  xmd: 992px,
  lg: 1200px,
  xl: 1440px,
  xxl: 1800px,
);

$fa-font-path:    "/fonts";
$enable-responsive-font-sizes: true;
$navbar-toggler-font-size: 1rem;

// Colors
$primary:     #e51b23;
$secondary:   #2f2f32;
$slate-gray:  #a7a9ac;
$dark-blue:   #1b3844;
$text-muted:  #75848a;

// Border Radius
$border-radius: 0.5rem;
$alert-border-radius: 0;

$alert-padding-y: 1.25rem;
$modal-inner-padding: 1.25rem;

// Buttons
$btn-padding-x: 1.5rem;
$btn-border-width: 3px;
$btn-border-radius: 10rem;
$btn-border-radius-sm: 10rem;
$btn-padding-x-sm: 1rem;

// SVG buttons
$ra-white: 'data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9J0xheWVyIDEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDE2My45MiA3Ny45NSc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTE2MS42OCA0MS4yMmwyLjI0LTIuMjUtMi4yNC0yLjI0LS43Ni0uNzZMMTI0Ljk1IDBsLTQuMjUgNC4yNCAzMS43NCAzMS43M0gwdjZoMTUyLjQ0TDEyMC43IDczLjdsNC4yNSA0LjI1IDM1Ljk3LTM1Ljk4Ljc2LS43NXonLz48L3N2Zz4=';
$la-white: 'data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9J0xheWVyIDEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDE2My45MiA3Ny45NSc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTIuMjQgMzYuNzNMMCAzOC45N2wyLjI0IDIuMjUuNzYuNzUgMzUuOTcgMzUuOTggNC4yNS00LjI1LTMxLjc0LTMxLjczaDE1Mi40NHYtNkgxMS40OEw0My4yMiA0LjI0IDM4Ljk3IDAgMyAzNS45N2wtLjc2Ljc2eicvPjwvc3ZnPg==';
$ra-gray: 'data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9J0xheWVyIDEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDE2My45MiA3Ny45NSc+PHBhdGggZmlsbD0nIzJmMmYyZicgZD0nTTE2MS42OCA0MS4yMmwyLjI0LTIuMjUtMi4yNC0yLjI0LS43Ni0uNzZMMTI0Ljk1IDBsLTQuMjUgNC4yNCAzMS43NCAzMS43M0gwdjZoMTUyLjQ0TDEyMC43IDczLjdsNC4yNSA0LjI1IDM1Ljk3LTM1Ljk4Ljc2LS43NXonLz48L3N2Zz4=';
$la-gray: 'data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9J0xheWVyIDEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDE2My45MiA3Ny45NSc+PHBhdGggZmlsbD0nIzJmMmYyZicgZD0nTTIuMjQgMzYuNzNMMCAzOC45N2wyLjI0IDIuMjUuNzYuNzUgMzUuOTcgMzUuOTggNC4yNS00LjI1LTMxLjc0LTMxLjczaDE1Mi40NHYtNkgxMS40OEw0My4yMiA0LjI0IDM4Ljk3IDAgMyAzNS45N2wtLjc2Ljc2eicvPjwvc3ZnPg==';

// Typography
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;

$adminBar: 37px;
