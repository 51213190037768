.homepage-hero {
  position: relative;
  background: #eeeeee;
  //min-height: 500px;
  //&:after {
  //  content: '';
  //  background:linear-gradient(-30deg, rgba(27,56,68,0.35) 0%, rgba(27,56,68,0.65) 45%, rgba(27,56,68,1) 100%);
  //  position: absolute;
  //  top:0;
  //  left:0;
  //  width: 100%;
  //  height: 100%;
  //}
  .hero-overlay {
    position: absolute;
    background:linear-gradient(-30deg, rgba(27,56,68,0.35) 0%, rgba(27,56,68,0.65) 45%, rgba(27,56,68,1) 100%);
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .homepage-hero-inner {
    margin-top: 1.5rem;
    margin-bottom: 25%;
    //h1 {
    //  font-size: 4rem;
    //}
    @include media-breakpoint-up(md) {
      margin-bottom: 20%;
      width: calc(66.66667% - 15px);
    }
  }
}

// Homepage Section Wrapper
.home-main-content {
  position: relative;
  background: $white;
  &::before {
    content: '';
    position: absolute;
    background: $white;
    display: block;
    height: 90%;
    top:0;
    left: 0;
    right: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    transform: skewY(-3deg);
  }
}

// Homepage Hero Cards
#heroCards {
  .col {
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }
  .hero-card-link {
    outline: 0;
    border-radius: 0.5rem;
    text-decoration: none;
    &:hover {
      .hero-card {
        transform: translateY(-3px);
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 30px 60px -12px rgba($secondary,.25),0 18px 36px -18px rgba($secondary,.3),0 -12px 36px -8px rgba($secondary,.025);
        }
      }
      .hero-card-span {
        margin-right: -0.5rem;
      }
    }
    &:focus {
      .hero-card {
        box-shadow: 0 0 0 0.3rem rgba($secondary, 1);
      }
    }
  }
  .hero-card {
    transform: translateY(0);
    transition-property: transform,-webkit-transform;
    transition-duration: 100ms;
    transition-timing-function: ease;
    .card-body {
      padding: 2rem;
      z-index: 1;
    }
    h2.card-title {
      font-family: "Noto Sans 400", sans-serif;
    }
    .hero-card-span {
      padding: 0.375rem 0.5rem 0.375rem 1.5rem;
      color: $secondary;
      transition: margin-right .15s ease-in-out;
      @include heroCardGrayBtn;
    }
    &:after {
      content: '';
      border-radius: 0.5rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 13px 27px -5px rgba($secondary,.25),0 8px 16px -8px rgba($secondary,.3),0 -6px 16px -6px rgba($secondary,.025);
      transition-property: box-shadow,-webkit-box-shadow;
      transition-duration: 100ms;
      transition-timing-function: ease;
    }
  }
  .hero-card-charcoalBlack,
  .hero-card-darkBlue,
  .hero-card-red {
    color: $white;
    .hero-card-span {
      color: $white;
      @include heroCardWhiteBtn;
    }
  }
  .hero-card-overlay,
  .hero-card-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .hero-icon {
    max-width: 70px;
    margin-right: 1rem;
    margin-left: -0.5rem;
    width: 100%;
  }
}

// Homepage Intro
.homepage-intro-inner {
  @include media-breakpoint-up(md) {
    //margin-bottom: 20%;
    max-width: 66.66667%;
    margin-right: auto;
    margin-left: auto;
  }
}

// Homepage Feature Rotator
#homepageFeatured {
  //background: linear-gradient(to bottom, $white,$slate-gray);
  position: relative;
  .flexslider {
    margin-bottom: 0;
    border-color: transparent;
    background: transparent;
    z-index: 2;
  }
  // Flexslider Styles
  .flexslider-img {
    flex: 0 0 100%;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    @include media-breakpoint-up(xmd) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .flexslider-copy {
    flex: 0 0 100%;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    @include media-breakpoint-up(xmd) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .flexslider-img {
    flex-shrink: 0;
    box-shadow: 0 30px 60px -12px rgba($secondary,.25),0 18px 36px -18px rgba($secondary,.3),0 -12px 36px -8px rgba($secondary,.025);
    @include media-breakpoint-up(xmd) {
      position: relative;
      top: 1rem
    }
  }
  .flexslider-copy {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 5rem;
    @include media-breakpoint-up(md) {
      //top: -1rem;
      padding-left: 1.5rem;
      margin-bottom: 3rem;
      font-size: .875rem;
    }
    @include media-breakpoint-up(xmd) {
      top: -1rem;
      //padding-left: 1.5rem;
      //margin-bottom: 3rem;
      //font-size: .875rem;
    }
    @include media-breakpoint-up(xmd) {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 3rem;
    }
  }
  .flexslider-navigation {
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 10;
    .flex-prev,
    .flex-next {
      display: inline-block;
      padding: 0.5rem;
    }
    .flex-prev {
      &::after {
        content: '';
        position: relative;
        top: 2px;
        width: 35px;
        height: 16.6px;
        display: inline-block;
        background-image:url($la-gray);
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% auto;
      }
    }
    .flex-next {
      &::after {
        content: '';
        position: relative;
        top: 2px;
        width: 35px;
        height: 16.6px;
        display: inline-block;
        background-image:url($ra-gray);
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% auto;
      }
    }
    @include media-breakpoint-up(xmd) {
      bottom: 30px;
    }
  }
  .featured-btn-link {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  &::before {
    content: '';
    background: linear-gradient(to bottom, $white, rgba($slate-gray, .5));
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
  }
}

#homepageFeed {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background: $white;
    display: block;
    height: 90%;
    top:0;
    left: 0;
    right: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: skewY(3deg);
    -ms-transform: skewY(3deg);
    transform: skewY(3deg);
  }
}