.site-footer {
  position: relative;
//  position: absolute;
//  bottom: 0;
//  width: 100%;
  &::before {
    content: '';
    position: absolute;
    background: $dark-blue;
    display: block;
    height: 90%;
    top:0;
    left: 0;
    right: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: skewY(3deg);
    -ms-transform: skewY(3deg);
    transform: skewY(3deg);
  }
  a {
    &:focus {
      outline-color: $white;
    }
  }
  .footer-social-link {
    background-color: $white;
    border-radius: 100%;
    padding: 0.5rem;
    &:hover {
      background-color: rgba($white, .75);
    }
    &:focus {
      background-color: rgba($white, .75);
      box-shadow: 0 0 0 0.2rem $white;
      outline: 0;
    }
  }
  .footer-social-icon {
    fill: $dark-blue;
    width: 22px;
    height: 22px;
  }
}
