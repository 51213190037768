.site-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  .navbar {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    opacity: 1;
    visibility: visible;
    z-index: 14;
    transition: opacity 0.15s ease, visibility 0.15s ease;
    &.navbar-fade {
      opacity: 0;
      visibility: hidden;
    }
    @include media-breakpoint-up(md) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  .navbar-toggler {
    position: relative;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    &:hover {
      .hamburger-fillings {
        &:before {
          background-color: $primary;
        }
      }
    }
    &:focus {
      outline: 0;
      .hamburger-fillings {
        &:before {
          background-color: $primary;
          box-shadow: 0 0 0 0.2rem $dark-blue;
        }
      }
    }
    &[aria-expanded="true"] {
      .hamburger-fillings {
        .hamburger-filling:nth-child(1),
        .hamburger-filling:nth-child(2) {
          margin: 0;
        }
        .hamburger-filling:nth-child(1) {
          transform: translate(0, 12px) rotate(45deg);
        }
        .hamburger-filling:nth-child(2) {
          transform: translate(0,10px) rotate(-45deg);
        }
        .hamburger-filling:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .hamburger-fillings {
    display: block;
    width: 20px;
    height: 26px;
    margin-left: 1.5rem;
    &:before {
      content: '';
      position: absolute;
      border: 3px solid $primary;
      border-radius: 100%;
      top: -9.5px;
      right: -12.5px;
      width: 45px;
      height: 45px;
      transition: background-color .15s ease-in-out;
    }
    .hamburger-filling {
      position: relative;
      display: block;
      background-color: $white;
      height: 2px;
      margin: 5px 0;
      z-index: 1;
    }
  }
  #navOverlay {
    position: fixed;
    background: rgba($dark-blue, .95);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x:hidden;
    margin-right:-15px;
    z-index: 12;
    .nav-link {
      color: $white;
    }
  }
  .parent-item {
    font-size: 1.125rem;
    &.descendants {
      border-bottom: 1px solid rgba($white, .5);
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }
}
.logged-in {
  #navOverlay {
    margin-top: $adminBar;
  }
}
.header-logo-svg {
  width: 150px;
  height: 46px;
  @include media-breakpoint-up(md) {
    width: 185px;
    height: 56px;
  }
}

