// Block Sections
.section-text-inset {
   display: block;
   max-width: 820px;
   margin-right: auto;
   margin-left: auto;
}
.cb-inner {
   position: relative;
}
.cb-container {
   position: relative;
   &:first-child {
      @extend .pt-medium;
      &::before {
         content: '';
         position: absolute;
         background: inherit;
         display: block;
         height: 110%;
         top: 0;
         left: 0;
         right: 0;
         -webkit-backface-visibility: hidden;
         -webkit-transform-origin: left bottom;
         -ms-transform-origin: left bottom;
         transform-origin: left bottom;
         -webkit-transform: skewY(-3deg);
         -ms-transform: skewY(-3deg);
         transform: skewY(-3deg);
      }
   }
   &:last-child {
      .cb-container-inner {
         @extend .mb-medium;
      }
      @extend .pb-max;
   }
}
.bg-darkBlue, .bg-red, .bg-charcoalBlack {
   .cb-accordion .card-body, .cb-copy  {
      a {
         color: inherit;
         text-decoration: underline;
         &:focus{
            outline: 2px solid $white;
         }
      }
   }
   .cb-employees {
      .employee-contact-link {
         svg path {
            fill: $white;
         }
      }
      .employee-card {
         a {
            &:focus{
               outline: 2px solid $white;
            }
         }
      }
   }
   .accordion-icon svg path:nth-child(2) { fill:$white; }
   .cb-form {
      .btn {
         @extend .btn-outline-light;
      }
   }
}
.bg-red {
   .accordion-icon svg path:nth-child(1) { fill:$white; }
   .cb-contentColumns .column-icon-wrapper span { border-color: $white; }
   .cb-blockquote { border-left-color: $white; }
   ul.invalid-feedback {
      background-color: $secondary;
      color: $white;
      &:before {
         border-bottom-color: $secondary;
      }
   }
}
.bg-slateGray {
   .cb-form {
      .btn {
         @extend .btn-outline-secondary;
      }
   }
}

// fion Block
.cb-accordion {
   .accordion-heading {
      font-size: 1.25rem;
      margin-top: 0.125rem;
      margin-left: 0.75rem;
      margin-bottom: 0;
      text-align: left;
   }
   .accordion-link {
      text-decoration: none;
      display: flex;
      padding: 1.25rem 0;
      -webkit-appearance: none;
      &.collapsed {
         .accordion-icon svg {
            transform: rotate(45deg);
         }
      }
      &:focus {
         text-decoration: underline;
      }
   }
   .accordion-icon {
      width: 30px;
      height: 30px;
      svg {
         fill: $secondary;
         width: 100%;
         height: 100%;
         transform: rotate(-90deg);
         transition: transform 100ms ease;
      }
   }
}
// Blockquote
.cb-blockquote {
   border-left: 5px solid $primary;
   padding-left: 1.5rem;
   font-family: "Noto Sans 700", sans-serif;
   font-size: 1.75rem;
   @include media-breakpoint-down(xmd) {
      font-size: calc(1.3rem + 0.6vw)
   }
   .blockquote-footer {
      color: inherit;
      opacity: .8;
      font-size: 75%;
   }
}
// Buttons
.cb-buttons {
   .btn {
      margin-bottom: 0.5rem;
      &:not(:last-of-type) {
         margin-right: 0.25rem;
      }
   }
}

// Copy Blocks
.cb-sectionHeading {
}
.cb-copy {
   figcaption {
      margin-top: 0.5rem;
      @extend .figure-caption;
   }
   &.cb-copy-2 {
      column-count: 2;
   }
   &.cb-copy-3 {
      column-count: 3;
   }
   &[class*="cb-copy-"] {
      column-gap: 3rem;
      @include media-breakpoint-down(md){
         column-count: 1;
      }
   }
   img {

      @include media-breakpoint-down(md) {
         display: block;
         margin-right: auto;
         margin-left: auto;
      }
   }
   .copy-figure-left,
   .copy-figure-right {
      @include media-breakpoint-up(md) {
         width: 40%;
      }
   }
   .copy-figure-left {
      @include media-breakpoint-up(md) {
         float: left;
         margin-right: 30px;
         // margin-left: 15px;
      }
   }
   .copy-figure-right {
      @include media-breakpoint-up(md) {
         float: right;
         margin-left: 30px;
         // margin-right: 15px;
      }
   }
}
// Content Columns
.cb-contentColumns {
   a.column-link {
      &:before {
         content:'';
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         width: 100%;
         height: 100%;
      }
   }
   &.contentColumns-horizontal {
      .col {
         flex: 0 0 100%;
         max-width: 100%;
      }
      .has-img {
         &.col:not(:last-child) {
            margin-bottom: 3rem;
         }
      }
      @include media-breakpoint-up(sm) {
         .card {
            flex-direction: row;
         }
         .has-img {
            .card {
               flex-direction: column;
            }
         }
      }
      @include media-breakpoint-up(md) {
         .has-img {
            &.col:not(:last-child) {
               margin-bottom: 5rem;
            }
            &.col:nth-child(2n) {
               .column-image-wrapper {
                  order: 2;
               }
               .card-body {
                  order: 1;
               }
            }
            .card {
               flex-direction: row;
            }
            .column-image-wrapper {
               width: 33.33333%;
            }
            .card-body {
               width: 66.66667%;
            }
         }
      }
   }
   &.contentColumns-vertical {
      .col {
         flex: 0 0 100%;
         max-width: 100%;
         margin-bottom: 1rem;
      }
      &.contentColumns-2, &.contentColumns-3, &.contentColumns-4 {
         @include media-breakpoint-up(md) {
            .col {
               flex: 0 0 50%;
               max-width: 50%;
            }
         }
      }
      &.contentColumns-3 {
         @include media-breakpoint-up(xmd) {
            .col {
               flex: 0 0 33.33333%;
               max-width: 33.33333%;
            }
         }
      }
      &.contentColumns-4 {
         @include media-breakpoint-up(lg) {
            .col {
               flex: 0 0 25%;
               max-width: 25%;
            }
         }
      }
   }
   .card.text-center {
      .column-icon-wrapper {
         justify-content: center;
      }
   }
   .column-icon-wrapper {
      display: flex;
      font-size: 1.75rem;
      span {
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 100%;
         border: 3px solid $primary;
         width: 3.75rem;
         height: 3.75rem;
      }
      @include media-breakpoint-up(sm) {
         padding: 0.5rem 0;
      }
   }
}

// Employee Block
.employee-contact-link {
   padding: 0.325rem;
   position: relative;
   z-index: 1;
   svg {
      path {
         fill: $dark-blue;
      }
      width: 25px;
      height: 25px;
   }
   &:hover {
      opacity: .75;
   }
}
.cb-employees {
   a.employee-modal-link {
      color: inherit;
      text-decoration: none;
      -webkit-appearance: none;
      &:after {
         content:'';
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         width: 100%;
         height: 100%;
      }
      &:hover {
         opacity: 0.75;
      }
   }
   .employee-card {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 2rem;
      .card {
         flex-direction: row;
      }
      .employee-card-img {
         overflow: hidden;
         max-width: 35%;
         width: 100%;
      }
      .card-body {
         text-align: left;
         align-self: center;
      }
   }
   .modal-employee-details-wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.25rem;
   }
   .modal-employee-img {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
   }
   .modal-employee-details {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      padding: 1.25rem;
      align-self: center;
   }
   @include media-breakpoint-up(sm) {
      .employee-card {
         flex: 0 0 50%;
         max-width: 50%;
         .card {
            flex-direction: column;
         }
         .employee-card-img {
            max-width: 80%;
            width: 100%;
            margin-right: auto;
            margin-left: auto;
         }
         .card-body {
            text-align: center;
         }
      }
   }
   @include media-breakpoint-up(md) {
      .card {
         flex-direction: row;
      }
   }
   @include media-breakpoint-up(xmd) {
      &.employees-2, &.employees-3 {
         .employee-card {
            .card-body {
               text-align: left;
            }
         }
         .card {
            flex-direction: row;
         }
      }
      &.employees-2 {
         .employee-card {
            flex: 0 0 50%;
            max-width: 50%;
            .employee-card-img {
               max-width: 40%;
               width: 100%;
            }
         }
      }
      &.employees-3 {
         .employee-card {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            .employee-card-img {
               max-width: 80%;
               width: 100%;
            }
            .card-body {
               text-align: center;
            }
         }
         .card {
            flex-direction: column;
         }
      }
      &.employees-4 {
         .employee-card {
            flex: 0 0 25%;
            max-width: 25%;
            .employee-card-img {
               max-width: 80%;
               width: 100%;
            }
            .card-body {
               text-align: center;
            }
         }
         .card {
            flex-direction: column;
         }
      }
   }
   @include media-breakpoint-up(lg) {
      &.employees-3 {
         .employee-card {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            .employee-card-img {
               max-width: 40%;
               width: 100%;
            }
            .card-body {
               text-align: left;
            }
         }
         .card {
            flex-direction: row;
         }
      }
   }
}

// Video Block
.cb-video {
   .col {
      &:only-child {
         padding-right:0;
         padding-left:0;
      }
      &:nth-child(odd) {
         padding-left:0;
      }
      &:nth-child(even) {
         padding-right:0;
      }
      &:not(:only-child) {
         flex: 0 1 100%;
      }
      @include media-breakpoint-up(xmd) {
         &:not(:only-child) {
            flex: 0 1 50%;
            max-width: 50%;
         }
      }
      @include media-breakpoint-down(md) {
         &:not(:last-child) {
            margin-bottom: 1.25rem;
         }
         padding-left:0;
         padding-right:0;
      }
   }
}
// Forms
.cb-form {

   label.form-check-label{
      font-family: "Noto Sans 200", sans-serif;
            text-transform: none;

 }
   .btn {
      @extend .btn-outline-primary;
   }
   label {
      font-size: 0.75rem;
      font-family: "Noto Sans 700", sans-serif;
      text-transform: uppercase;
    
      .required::after {
         color: inherit;
      }


   }
}
.form-control:focus {
   border-color: $secondary;
   background-color: #f5f5f5;
   color: $secondary;
   box-shadow: 0 0 0 0.125rem $secondary;
}
ul.invalid-feedback {
   position: relative;
   background-color: $danger;
   border-radius: 0.25rem;
   color: $white;
   font-size: 1rem;
   list-style: none;
   padding: 0.5rem 1rem;
   margin-top: 1rem;
   &:before {
      content: "";
      width: 0;
      height: 0;
      border: transparent 8px solid;
      border-bottom-color: $danger;
      display: block;
      position: absolute;
      top: -16px;
      right: 15px;
   }
}


.ezMap {
   height:350px;
}
//.section-wrap-alt-inner {
//   h2.sectionHeading {
//      display: block;
//      max-width: 820px;
//      margin-right: auto;
//      margin-left: auto;
//   }
//}

// Blockquote
//.cb-blockquote {
//   //background-color: $brand-lighter-blue;
//   border-radius: 0.25rem;
//   color: $secondary;
//   margin-top: 1.5rem;
//   margin-bottom: 1.5rem;
//   padding: 1.25rem 1.5rem;
//   .blockquote-footer {
//      color: #4B5157;
//   }
//   &.cb-blockquote-center {
//      padding-left: 15px;
//      max-width: 820px;
//      margin-right: auto;
//      margin-left: auto;
//   }
//   &.cb-blockquote-left,
//   &.cb-blockquote-right {
//      @include media-breakpoint-up(md) {
//         width: 45%;
//      }
//      @include media-breakpoint-up(xmd) {
//         width: 35%;
//      }
//   }
//   &.cb-blockquote-left {
//      @include media-breakpoint-up(md) {
//         float: left;
//         margin-right: 30px;
//      }
//   }
//   &.cb-blockquote-right {
//      @include media-breakpoint-up(md) {
//         float: right;
//         margin-left: 30px;
//      }
//   }
//}

// Employees

//[id*='employees-board-members-'] {
//   &.cb-employees {
//      &[class*="employees-"] .col {
//         .media {
//            margin-bottom: 1.875rem!important;
//            .media-header {
//               margin-bottom:0;
//            }
//         }
//      }
//   }
//}

//
//// Form & HTML Block
//.cb-form, .cb-html, .cb-plainText {
//   margin-top:1.25rem;
//   margin-bottom:1.25rem;
//}
//
//// Image Block
//.cb-image {
//   overflow: hidden;
//   img {
//      width: 100%;
//      @include media-breakpoint-down(md) {
//         display: block;
//         margin-right: auto;
//         margin-left: auto;
//      }
//   }
//   .figure-caption {
//      text-align: center;
//      p:last-of-type {
//         margin-bottom: 0;
//      }
//   }
//   &.entry-image-full,
//   &.entry-image-center {
//      margin-bottom: 1.875rem;
//      margin-top: 1.875rem;
//      .figure-caption {
//         @include media-breakpoint-up(md) {
//            max-width: 820px;
//            margin-right: auto;
//            margin-left: auto;
//         }
//      }
//   }
//   &.entry-image-full {
//      .figure-caption {
//         @include media-breakpoint-down(md) {
//            padding-left: 15px;
//            padding-right: 15px;
//         }
//      }
//   }
//   &.entry-image-full {
//      margin-right: -15px;
//      margin-left: -15px;
//      img {
//         border-radius:0!important;
//         width: 100%;
//      }
//   }
//   &.entry-image-left,
//   &.entry-image-right {
//      @include media-breakpoint-up(md) {
//         width: 40%;
//      }
//   }
//   &.entry-image-left {
//      @include media-breakpoint-up(md) {
//         float: left;
//         margin-right: 30px;
//         // margin-left: 15px;
//      }
//   }
//   &.entry-image-right {
//      @include media-breakpoint-up(md) {
//         float: right;
//         margin-left: 30px;
//         // margin-right: 15px;
//      }
//   }
//   @include media-breakpoint-up(md) {
//      .figure-caption {
//         text-align: left;
//      }
//   }
//}
