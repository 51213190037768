#app {
  min-height: calc(100vh - 94px);
  @include media-breakpoint-up(md) {
    min-height: calc(100vh - 152px);
  }
}
.page-header {
  background-color: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    background:linear-gradient(-30deg, rgba(27,56,68,0.35) 0%, rgba(27,56,68,0.65) 45%, rgba(27,56,68,1) 100%);
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  //height: 40vh; /* if you don't want it to take up the full screen, reduce this number */
  //overflow: hidden;
  .page-header-inner {
    position: relative;
    z-index: 1;
    margin-top: 1.5rem;
    margin-bottom: 15%;
    @include media-breakpoint-up(md) {
      margin-bottom: 10%;
      width: calc(66.66667% - 15px);
    }
  }
}
.employee-page-header {
  padding-bottom: 8%;
  @include media-breakpoint-up(md) {
    padding-bottom: 5%;
  }
}
#newsFeed,
#employeeContent,
#search-results{
  min-height: 50vh;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background: #fff;
    display: block;
    height: 90%;
    top: 0;
    left: 0;
    right: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    transform: skewY(-3deg);
  }
}
.news-nav {
  a {
    color: $dark-blue;
    &:hover {
      color: lighten($dark-blue, 15%)
    }
  }
  .nav-link.active {
    background-color: $dark-blue;
  }
}
.news-card-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    .btn-outline-primary {
      color: $white;
      background-color: #e51b23;
    }
  }
  &:focus {
    .btn-outline-primary {
      box-shadow: 0 0 0 0.2rem rgba(229, 27, 35, 0.5);
    }
    outline: 0;
  }
  @media screen and (max-width: 767px) {
    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media screen and (min-width:768px) and (max-width:991px) {
    .card {
      flex-direction: row;
    }
    .news-image-wrapper {
      width: 33.33333%;
    }
    .card-body {
      width: 66.66667%;
      padding-top: 0;
    }
  }
  @media screen and (min-width: 992px) {
    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.accordion {
  & > .card {
    background-color: transparent;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    & > .card-header {
      background-color: transparent;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
  .card-body {
    padding-top: 0;
    padding-left: 2.75rem;
  }
}